<template>
    <b-card no-body class="mb-4 p-4">
        <div class="d-flex justify-content-end">
            <b-button variant="success" @click="exportToExcel" class="mb-3"> Export to Excel </b-button>
        </div>
        <div>
            <div class="grid border">
                <div class="bg-light" v-for="field in fields" :key="field.key">
                    <div class="p-2" style="font-weight: bold">{{ field.label }}</div>
                </div>
            </div>
            <div class="grid border" v-for="(item, idx) in items" :key="item.id">
                <div class="" v-for="field in fields" :key="field.key">
                    <div class="p-2">
                        <span v-if="field.key === 'action'">
                            <b-button v-b-toggle="`accordion-${idx}`" variant="primary" size="sm"
                                >Toggle Details</b-button
                            >
                        </span>
                        <span v-else>
                            <template v-if="item[field.key]">{{ item[field.key] }}</template>
                            <template v-else>N/A</template>
                        </span>
                    </div>
                </div>
                <b-collapse :id="`accordion-${idx}`" class="w-100" style="grid-column: span 9 / span 9">
                    <div class="px-3 pb-2">
                        <h6 class="mt-2 ml-2">Customers pending verification</h6>
                        <b-table
                            style="height: 250px"
                            :items="item.employees"
                            :fields="detailsFields"
                            small
                            responsive
                            bordered
                            head-variant="light"
                            hover
                        >
                            <template #cell(submitedToReviewDate)="data">{{
                                $formatDate(data.item.submitedToReviewDate)
                            }}</template>
                            <template #cell(fromDate)="data">{{ $formatDate(data.item.fromDate) }}</template>
                            <template #cell(toDate)="data">{{ $formatDate(data.item.toDate) }}</template>
                            <template #cell(isVerified)="data">
                                <b-dropdown variant="primary" id="dropdown-1" text="Actions" class="">
                                    <b-dropdown-item @click="goToCustomer(data.item)">Go to customer</b-dropdown-item>
                                    <b-dropdown-item @click="showEditModal(data.item)">Edit Employment</b-dropdown-item>
                                    <b-dropdown-item v-if="!data.item.isActive" @click="updateStatus(true, data.item)">
                                        Archive
                                    </b-dropdown-item>
                                    <!-- <b-dropdown-item v-else @click="updateStatus(false, data.item)">
                                        Active
                                    </b-dropdown-item> -->
                                </b-dropdown>
                            </template>
                        </b-table>
                    </div>
                </b-collapse>
            </div>
        </div>

        <b-modal
            id="modal-update-job-verification"
            title="Edit Employment"
            header-bg-variant="light"
            hide-footer
            centered
            @close="clearValues"
        >
            <b-form @submit.prevent="updateInfo" v-if="employerSelected">
                <b-card-text>
                    <b-form-group
                        label="Job Description"
                        label-for="job-description"
                        label-class="fw-medium"
                        class="mb-2"
                    >
                        <b-form-input
                            id="job-description"
                            type="text"
                            size="sm"
                            placeholder="Job Description"
                            v-model="employerSelected.jobDescription"
                            required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Hire Date" label-for="from-date" label-class="fw-medium" class="mb-2">
                        <b-form-input
                            id="from-date"
                            type="date"
                            size="sm"
                            placeholder="Hire Date"
                            v-model="employerSelected.fromDate"
                            required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group label="End Date" label-for="to-date" label-class="fw-medium" class="mb-2">
                        <b-form-input
                            id="to-date"
                            type="date"
                            size="sm"
                            placeholder="End Date"
                            v-model="employerSelected.toDate"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        label="Monthly Salary"
                        label-for="monthly-salary"
                        label-class="fw-medium"
                        class="mb-2"
                    >
                        <b-form-input
                            id="monthly-salary"
                            type="number"
                            size="sm"
                            placeholder="Monthly Salary"
                            v-model="employerSelected.monthlySalary"
                            required
                            step="0.01"
                            min="1"
                        ></b-form-input>
                    </b-form-group>

                    <!-- RadioButton validate/invalidate -->
                    <label for="is-verified" class="mb-0">Verify Employment</label>
                    <b-form-radio-group
                        id="is-verified"
                        v-model="employerSelected.isVerified"
                        name="is-verified"
                        :options="[
                            { text: 'Validate', value: true },
                            { text: 'Invalidate', value: false },
                        ]"
                        class="my-2"
                    ></b-form-radio-group>

                    <!-- comments -->
                    <b-form-group label="Comments" label-for="comments" label-class="fw-medium" class="mb-2">
                        <b-form-textarea
                            id="comments"
                            size="sm"
                            placeholder="Comments"
                            v-model="employerSelected.verificationComments"
                        ></b-form-textarea>
                    </b-form-group>

                    <b-button block :disabled="loading" variant="primary" type="submit" class="mt-3">
                        <b-spinner small v-if="loading"></b-spinner>
                        <span v-else>Update Employment</span>
                    </b-button>
                </b-card-text>
            </b-form>
        </b-modal>
    </b-card>
</template>

<script>
import * as dayjs from 'dayjs';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export default {
    data() {
        return {
            filter: null,
            items: [],
            fields: [
                { key: 'businessID', label: 'Company ID' },
                { key: 'businessName', label: 'Company Name' },
                { key: 'businessPhone', label: 'Company Phone' },
                { key: 'businessEmail', label: 'Company Email' },
                { key: 'contactPersonName', label: 'Contact Person' },
                { key: 'contactPersonPhone', label: 'Contact Phone' },
                { key: 'contactPersonEmail', label: 'Contact Email' },
                { key: 'businessComments ', label: 'Business Comments' },
                { key: 'action', label: 'Action', isRowHeader: true },
            ],
            detailsFields: [
                { key: 'submitedToReviewDate', label: 'Date Submitted' },
                { key: 'nationalID', label: 'National ID' },
                { key: 'firstName', label: 'First Name' },
                { key: 'lastName', label: 'Last Name' },
                { key: 'jobDescription', label: 'Job Description' },
                { key: 'monthlySalary', label: 'Salary' },
                { key: 'fromDate', label: 'Hire Date' },
                { key: 'toDate', label: 'End Date' },
                { key: 'isVerified', label: 'Actions' },
            ],
            employerSelected: {
                jobDescription: '',
                fromDate: '',
                toDate: '',
                monthlySalary: '',
                isVerified: false,
            },
            loading: false,
        };
    },
    methods: {
        async getData() {
            const response = await this.$store.dispatch('customer/employments/get_employmentJobVerifications');

            if (response) {
                this.items = response ?? [];
            }
        },
        formatDate(date) {
            if (!date) return;

            return dayjs(date).format('YYYY-MM-DD');
        },
        showEditModal(data) {
            console.log(data);
            this.employerSelected = data;
            this.employerSelected.fromDate = this.formatDate(data.fromDate);
            this.employerSelected.toDate = this.formatDate(data.toDate);
            this.$bvModal.show('modal-update-job-verification');
        },
        clearValues() {
            this.employerSelected = null;
        },
        async updateInfo() {
            this.loading = true;

            const response = await this.$store.dispatch('customer/employments/update_employment', {
                ...this.employerSelected,
                toDate: this.employerSelected.toDate || null,
            });

            if (response) {
                this.$bvModal.hide('modal-update-job-verification');
                this.getData();
            }

            this.loading = false;
        },
        async updateStatus(status, item) {
            let data = {
                id: item.id,
                isActive: status,
                modifiedBy: 1,
            };
            await this.$store.dispatch('customer/employments/update_employmentStatus', data);
            await this.getData();
        },
        goToCustomer(item) {
            this.$router.push(`/customers/list/${item.customerId}`);
        },
        exportToExcel() {
            const workbook = new ExcelJS.Workbook();

            const filteredFieldsItem = this.fields.filter((field) => field.key !== 'action');
            const filteredFieldsDetails = this.detailsFields.filter((field) => field.key !== 'isVerified');

            this.items.forEach((item) => {
                const sheetName = `${item.businessID} - ${item.businessName}`;
                const trimmedSheetName = sheetName.replace(/[/\\?%*:|"<>]/g, '_').substring(0, 27);

                const sheet = workbook.addWorksheet(`${trimmedSheetName}...`);
                const boldStyle = { bold: true };
                const headerFill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'c8d6e5' },
                };

                // Agregar encabezado para los detalles del negocio
                const itemHeaders = filteredFieldsItem.map((field) => field.label);
                const itemKeys = filteredFieldsItem.map((field) => field.key);

                const headerRow = sheet.addRow(itemHeaders);
                headerRow.eachCell((cell, colNumber) => {
                    cell.font = boldStyle;
                    cell.fill = headerFill;
                    sheet.getColumn(colNumber).width = Math.max(...itemHeaders.map((header) => header.length), 15); // Ajusta el ancho de la columna
                });

                // Agregar detalles del negocio
                const itemValues = itemKeys.map((key) => item[key] || '');
                const dataRow = sheet.addRow(itemValues);
                dataRow.eachCell((cell, colNumber) => {
                    sheet.getColumn(colNumber).width = Math.max(
                        sheet.getColumn(colNumber).width,
                        String(cell.value).length + 2,
                    ); // Ajusta el ancho si es necesario
                });

                // Separador y encabezado para los empleados
                sheet.addRow([]); // Fila vacía como separador
                const employmentTitleRow = sheet.addRow(['EMPLOYMENTS']);
                employmentTitleRow.font = boldStyle;
                employmentTitleRow.eachCell((cell, colNumber) => {
                    cell.fill = headerFill;
                    sheet.getColumn(colNumber).width = Math.max(
                        sheet.getColumn(colNumber).width,
                        String(cell.value).length + 2,
                    ); // Ajusta el ancho si es necesario
                });

                if (item.employees && item.employees.length > 0) {
                    // Agregar encabezados para los empleados
                    const employeeHeaders = filteredFieldsDetails.map((field) => field.label);
                    const employeeKeys = filteredFieldsDetails.map((field) => field.key);

                    const employeeHeaderRow = sheet.addRow(employeeHeaders);
                    employeeHeaderRow.eachCell((cell, colNumber) => {
                        cell.font = boldStyle;
                        cell.fill = headerFill;
                        sheet.getColumn(colNumber).width = Math.max(
                            ...employeeHeaders.map((header) => header.length),
                            15,
                        ); // Ajusta el ancho de la columna
                    });

                    // Agregar filas de empleados
                    const employees = item.employees.map((employment) => {
                        return {
                            ...employment,
                            submitedToReviewDate: this.formatDate(employment.submitedToReviewDate),
                            fromDate: this.formatDate(employment.fromDate),
                            toDate: this.formatDate(employment.toDate),
                        };
                    });
                    employees.forEach((employment) => {
                        const employmentValues = employeeKeys.map((key) => employment[key] || '');
                        const employeeDataRow = sheet.addRow(employmentValues);
                        employeeDataRow.eachCell((cell, colNumber) => {
                            sheet.getColumn(colNumber).width = Math.max(
                                sheet.getColumn(colNumber).width,
                                String(cell.value).length + 2,
                            ); // Ajusta el ancho si es necesario
                        });
                    });
                } else {
                    const noEmploymentRow = sheet.addRow(['No employments available']);
                    noEmploymentRow.font = boldStyle;
                    noEmploymentRow.eachCell((cell, colNumber) => {
                        cell.fill = headerFill;
                        sheet.getColumn(colNumber).width = Math.max(
                            sheet.getColumn(colNumber).width,
                            String(cell.value).length + 2,
                        ); // Ajusta el ancho si es necesario
                    });
                }
            });

            // Exportar el archivo Excel
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer]), 'Job-verifications-pending.xlsx');
            });
        },
    },
    created() {
        this.getData();
    },
};
</script>

<style scoped>
.grid {
    display: grid;
    grid-template-columns: repeat(9, minmax(0, 1fr));
}
</style>
